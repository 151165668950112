jQuery(document).foundation();


jQuery('#fact-carousel').owlCarousel({
    loop: true,
    margin:10,
    nav: true,
    autoplay: true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
})


jQuery('#hotel-carousel').owlCarousel({
    loop: true,
    margin:10,
		// navigationText : ["<i class='icon-left'></i>","<i class='icon-right'></i>"],
    nav: true,
    autoplay: true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
})

jQuery('#about-carousel').owlCarousel({
    loop: true,
    margin:30,
    center: true,
    nav: true,
    autoplay: true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:3
        }
    }
})

jQuery('#awards-carousel').owlCarousel({
    loop: true,
    margin:30,
    nav: false,
    autoplay: true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:3
        },
        1000:{
            items:4
        }
    }
})


//----------vide safari hack - https://github.com/VodkaBears/Vide/issues/206

// jQuery(document).ready(function() {
// 	//FORCE VIDEO PLAY - SAFARI 11 ISSUE
//   var videexists = document.getElementsByClassName('vide-container-here');
//   if (videexists !== null) {
//
//   	jQuery(window).load(function() {
//   		jQuery('.vide-container-here').data('vide').getVideoObject().play();
//   	});
//
//   }
//
// });



// html5 video play on scroll




//mute by default

jQuery("video").prop('muted');

var videos = document.getElementsByTagName("video"),
fraction = 0.8;
function checkScroll() {

    for(var i = 0; i < videos.length; i++) {

        var video = videos[i];

        var x = video.offsetLeft, y = video.offsetTop, w = video.offsetWidth, h = video.offsetHeight, r = x + w, //right
            b = y + h, //bottom
            visibleX, visibleY, visible;

            visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
            visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));

            visible = visibleX * visibleY / (w * h);

            if (visible > fraction) {
                video.play();
            } else {
                video.pause();
            }

    }

}

window.addEventListener('scroll', checkScroll, false);
window.addEventListener('resize', checkScroll, false);

jQuery(".scrolly").click(function(scroll3){
scroll3.preventDefault();
jQuery('html,body').animate({scrollTop:jQuery(this.hash).offset().top}, 1500);
});

// Owl

jQuery('#work-carousel').owlCarousel({
    loop: true,
    margin:10,
    nav: true,
    autoplay: true,
    navText: ['<i class="icon-left" aria-hidden="true"></i>','<i class="icon-right" aria-hidden="true"></i>'],
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
})

jQuery('#service-carousel').owlCarousel({
    loop: true,
    dots: true,
    margin:10,
    nav: false,
    autoplay: true,
    navText: ['<i class="icon-left" aria-hidden="true"></i>','<i class="icon-right" aria-hidden="true"></i>'],
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
})

jQuery("#local").owlCarousel({
			autoPlay: 1500, //Set AutoPlay to 3 seconds
			navigationText : ["<i class='icon-left'></i>","<i class='icon-right'></i>"],
			items : 2,
			itemsDesktop : [1199,2],
			itemsDesktopSmall : [979,2]
})


jQuery('#social').owlCarousel({
	loop: true,
	margin:10,
	nav: true,
	dots: true,
	autoplay: true,
  autoplayTimeout: 7000,
  autoplayHoverPause: true,
	navText: ['<i class="icon-left" aria-hidden="true"></i>','<i class="icon-right" aria-hidden="true"></i>'],
	responsive:{
			0:{
					items:1
			},
			600:{
					items:1
			},
			1000:{
					items:1
			}
	}
})

jQuery('#landing_page_carousel').owlCarousel({
    loop: true,
    margin:10,
		nav: true,
    autoplay: true,
		autoplayHoverPause: true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
})

// No orphens

jQuery('h1, h2, h3, h4, .service-content li').each(function(){
    var string = jQuery(this).html();
    string = string.replace(/ ([^ ]*)$/,'&nbsp;$1');
    jQuery(this).html(string);
});

// UI animation

// TweenMax.set('#preloader', {
// 	autoAlpha:1,display:"block",
// })
//
// TweenMax.set("#homeScene, #latest-work, .load-in", {transformOrigin:"50% 50%", yPercent: 5, opacity:0})
//
// TweenMax.set(".peeping", {transformOrigin:"50% 50%", yPercent: -50, opacity:0})
//
//
// // Landing page animations
//
//
// jQuery(window).on('load', function() { // makes sure the whole site is loaded
//
//   var loaderTl2 = new TimelineMax();
//
//   TweenMax.set('#rightArm', { transformOrigin: '50% 100%', xPercent: -110, yPercent: '50%',})
//   loaderTl2.to("#rightArm", 0.6, { xPercent: -10, yPercent: 0, transformOrigin:"50% 50%", delay:1, ease: Back.easeIn.config(0.5)},{	offset:'20%',	triggerOnce:true });
//
//   TweenMax.set('#leftArm', {transformOrigin: '50% 100%', xPercent: 110, yPercent: '50%', })
//   loaderTl2.to("#leftArm", 0.6, { xPercent: 10, yPercent: 0, transformOrigin:"50% 50%", delay:1, ease: Back.easeIn.config(0.5)},{	offset:'20%',	triggerOnce:true });
//
//
//   var thumbTL = new TimelineMax({});
//
// 	TweenMax.set('#rightArm', {
// 	  transformOrigin: '100% 0%',
// 	  rotation: 3
// 	})
//
// 	thumbTL.to("#rightArm", 1.3, {rotation:"0", ease:Linear.easeNone, repeat:-1, yoyo: true})
//
//
// 	var thumbRightTL = new TimelineMax({});
//
// 	TweenMax.set('#leftArm', {
// 		transformOrigin: '100% 100%',
// 		rotation: 0
// 	})
//
// 	thumbRightTL.to("#leftArm", 1.3, {rotation:"3", ease:Linear.easeNone, repeat:-1, yoyo: true})
//
//
//   // mailing list confirmation screens
//
//   var mailboxTL = new TimelineMax({});
//
//   TweenMax.set('.img-nearlythere', {
//     transformOrigin: '100% 100%',
//   })
//
//   mailboxTL.to(".img-nearlythere", 1.3, {y:-10, ease:Power4.easeInOut, repeat:-1, yoyo: true})
//
//   var thumbsupTL = new TimelineMax({});
//
//
//
//
//   TweenMax.set('.img-thankyou', {
//     transformOrigin: '100% 100%',
//   })
//
//   thumbsupTL.to(".img-thankyou", 1.3, {y:-10, ease:Power4.easeOutIn, repeat:-1, yoyo: true})
//
//
//   // Loader
//
//
//   var loaderTl = new TimelineMax();
//
//   loaderTl
// 	.to("#preloader", 0.5, {autoAlpha:0,display:"none", ease: Power4.easeInOut})
// 	.staggerTo("h1, #homeScene, #latest-work, .load-in", 1, {opacity:1, scale:1, yPercent: -1, ease: Power4.easeInOut}, '+=0.5');
//
//
// 	var PeepingTL = new TimelineMax();
//
// 	PeepingTL
// 	.to(".peeping", 1.5, {opacity:1, scale:1, yPercent: -1, ease: Power4.easeInOut});
//
// })


var controller = new ScrollMagic.Controller();


// JSON

var exists = document.getElementById('food-service');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('food-service'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/food-service.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}


var exists = document.getElementById('restaurants');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('restaurants'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/restaurants.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('start-ups');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('start-ups'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/start-up.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('retail');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('retail'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/retail.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}


//services - refering to eat-website-animations.ai

var exists = document.getElementById('service1');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service1'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service1.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service2');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service2'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service2.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service3');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service3'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service3.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service4');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service4'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service4.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service5');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service5'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service5.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service6');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service6'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service6.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service7');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service7'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service7.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service8');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service8'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service8.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service9');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service9'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service9.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service10');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service10'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service10.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service11');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service11'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service11.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service12');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service12'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service12.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service13');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service13'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service13.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service14');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service14'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service14.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service15');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service15'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service15.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('service16');
if (exists !== null) {

var whySceneData = {
	wrapper: document.getElementById('service16'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/services/service16.json'
};
var whyScene = bodymovin.loadAnimation(whySceneData);
whyScene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}



var exists = document.getElementById('homeScene');
if (exists !== null) {

//console.log('element found');

var animData = {
	wrapper: document.getElementById('homeScene'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/lower.json'
};

var anim = bodymovin.loadAnimation(animData);
anim.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}





var exists = document.getElementById('about1');
if (exists !== null) {


var about1SceneData = {
	wrapper: document.getElementById('about1'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
	path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/about-1.json'
};
var about1Scene = bodymovin.loadAnimation(about1SceneData);
about1Scene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('about2');
if (exists !== null) {


var about2SceneData = {
	wrapper: document.getElementById('about2'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
  path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/about-2.json'
};
var about2Scene = bodymovin.loadAnimation(about2SceneData);
about2Scene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}

var exists = document.getElementById('about3');
if (exists !== null) {


var about3SceneData = {
	wrapper: document.getElementById('about3'),
	animType: 'svg',
	loop: true,
	prerender: true,
	autoplay: true,
  path: 'https://eat-marketing.co.uk/wp-content/themes/eat/assets/scripts/js/json/about-3.json'
};
var about3Scene = bodymovin.loadAnimation(about3SceneData);
about3Scene.setSpeed(1);
bodymovin.setQuality(1);

} else {
  //console.log('element NOT found');
}



// Moblie Menu > Start

TweenMax.set("#menu", {borderRadius:"50% 50% 50% 50%", height: '0px',
    width: '0px', transformOrigin:"50% 50%", bottom:"50px", left:"50%"})

    TweenMax.set(".mobile-social a, .phone-link", {opacity: 0})

jQuery(document).ready(function () {
  var bars = jQuery('.menu-bar');
  var menu = jQuery('#menu');
	var menuLink = jQuery('#menu a, .mobile-social a, .phone-link');
  var menuButton = jQuery('.menu-button');
  var timeline = new TimelineMax({paused: true});
  var open = false;

  timeline
  .to(bars[0], 0.1, {
    top: '5px',
    rotation: 45,
  }, 0)
  .to(bars[1], 0.1, {
    opacity: 0
  }, 0)
  .to(bars[2], 0.1, {
    top: '-7px',
    rotation: -45,
  }, 0)
  .to(menu, 0.2, {
    height: '100%',
    width: '100%',
		top: 0,
		left:0,
		borderRadius:"0% 0% 0% 0%",
		ease: Power2.easeOut
  }, '-=0.2')
  .staggerTo(menuLink, 0.4, {
    opacity: 1,
		yPercent:-15,
		ease: Power2.easeOut
  })

  menuButton.on('click', function () {
    jQuery(this).toggleClass('active');
    if (open) {
      timeline.reverse();
      open = false;
    } else {
      timeline.play();
      open = true;
    }
  });

  //we can't close the menu on any a click now because we have sub menu items that need to expand and stay visible

  // menu.find('a').on('click', function () {
  //   timeline.reverse();
  // });
});
